import {getFhirClient} from './base/fhir-client';

const client = getFhirClient();

class PractitionerService {
  getPractitioners = async (query, cache = false) => {
    return await client.request({
      url: `Practitioner?${query}`,
      cache: cache ? 'no-cache' : 'default',
    });
  };

  getPractitioner = async id => {
    return await client.request({
      url: `Practitioner/${id}`,
      cache: 'no-cache',
    });
  };

  getPractitionerByQueryParams = async query => {
    return await client.request({
      url: query,
      cache: 'no-cache',
    });
  };

  createPractitioner = async practitioner => {
    return await client.request({
      url: 'Practitioner',
      method: 'POST',
      body: practitioner,
    });
  };

  updatePractitioner = async practitioner => {
    return await client.request({
      url: `Practitioner/${practitioner.id}`,
      method: 'PUT',
      body: practitioner,
    });
  };

  getPractitionerPatients = async practitionerId => {
    return await client.request({
      url: `CareTeam?participant=Practitioner/${practitionerId}`,
      method: 'GET',
    });
  };
}

const practitionerService = new PractitionerService();

export default practitionerService;
