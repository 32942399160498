export const RAVEN_WHITE = '#FFFFFF';
export const RAVEN_BLACK = '#000000';
export const RAVEN_GRAY = '#E6E7E8';

// GRAYSCALE
export const GRAY_50 = '#F5F5F5';
export const GRAY_100 = '#E9E9E9';
export const GRAY_200 = '#D9D9D9';
export const GRAY_300 = '#C4C4C4';
export const GRAY_400 = '#9D9D9D';
export const GRAY_500 = '#7B7B7B';
export const GRAY_600 = '#555555';
export const GRAY_700 = '#434343';
export const GRAY_800 = '#262626';

// REDSCALE
export const TORCH_RED_LIGHT = '#FD8DA4';
export const TORCH_RED_REGULAR = '#F20F4B';
export const TORCH_RED_DARK = '#CD0048';
export const TORCH_RED_800 = '#B50A46';
export const TORCH_RED_300 = '#FFE4E9';

// BLUESCALE
export const BLUE_50 = '#E3F3FE';
export const BLUE_100 = '#BBE1FE';
export const BLUE_200 = '#8FCEFF';
export const BLUE_300 = '#61BBFE';
export const BLUE_400 = '#3AACFF';
export const BLUE_500 = '#009EFF';
export const BLUE_600 = '#008FF1';
export const BLUE_700 = '#0569F7';
export const BLUE_800 = '#006BCC';
export const BLUE_900 = '#004DAD';

// TEXT - inclues RAVEN_WHITE and RAVEN_BLACK
export const TEXT_PRIMARY = '#404040';
export const TEXT_SECONDARY = '#616161';
export const TEXT_TERTIARY = '#9E9E9E';

// STAUTS
export const ERROR = '#C50F0F';
export const ERROR_LIGHT = 'rgba(197, 15, 15, 0.1)';
export const SUCCESS = '#00BD67';
export const SUCCESS_LIGHT = 'rgba(84, 185, 111, 0.06)';
export const INFO = '#10A9E1';
export const INFO_LIGHT = 'rgba(16, 169, 225, 0.06)';
export const ALERT = '#F8D02F';

// OPACITY STYLE
export const OPACITY_54 = 'rgba(0, 0, 0, 0.54)';
export const OPACITY_38 = 'rgba(0, 0, 0, 0.38)';
export const OPACITY_26 = 'rgba(0, 0, 0, 0.26)';
export const OPACITY_12 = 'rgba(0, 0, 0, 0.12)';
export const OPACITY_6 = 'rgba(0, 0, 0, 0.06)';

// WHITE OPACITY STYLE
export const WHITE_OPACITY_60 = 'rgba(255,255,255, 0.60)';

// PRIMARY COLORS
export const TORCH_RED = '#F20F4B';
export const BLUE_RIBBON = '#0569F7';

// SECONDARY_COLORS
export const BLAZE_ORANGE = '#FC7826';
export const HONEY_YELLOW = '#0569F7';
export const ELECTRIC_VIOLET = '#B400E0';
export const CAPRI = '#0569F7';
export const FLUORESCENT_BLUE = '#06F0F0';
export const SPRING_GREEN = '#0569F7';

export const SESSION_PURPLE = '#8E0CAE';

// GRADIENTS
export const USER_GRADIENT = ['#0454C6', '#0454C6'];
export const ADMIN_GRADIENT = ['#0454C6', '#A200CA', '#DA0E44'];
export const DEFAULT_GRADIENT = ['#DA0E44', '#D85D10'];
export const TRIAL_GRADIENT = ['#0454C6', '#00A8E6'];
