import React from 'react';
import {
  SafeAreaView,
  StyleSheet,
  Dimensions,
  ImageBackground,
} from 'react-native';
import {SPLASH} from 'src/styles/images';
const {height, width} = Dimensions.get('window');

const Splash = () => {
  return (
    <SafeAreaView style={styles.screenContainer}>
      <ImageBackground source={SPLASH} style={styles.backgroundImage} />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  screenContainer: {
    height: height,
    width: width,
  },
  backgroundImage: {
    flex: 1,
    width: width,
    height: height,
  },
});

export default Splash;
