import React from 'react';
import {Text, StyleSheet, View} from 'react-native';
import * as colors from 'src/styles/colors';
import {Typography} from "src/styles";

interface props {
  customTitle: String;
  style?: {};
  right?: () => JSX.Element;
}

const Header = ({customTitle, style = {}, right = () => <></>}: props) => {
  return (
    <View style={styles.container}>
      <Text style={{...styles.text, ...style}}>{customTitle}</Text>
      <View style={styles.rightCompContainer}>{right()}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    marginBottom: 36,
  },
  text: {
    flexDirection: 'row',
    alignSelf: 'center',
    fontWeight: 'bold',
    textAlign: 'left',
    ...Typography.H1,
    color: colors.RAVEN_BLACK,
  },
  rightCompContainer: {
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignContent: 'flex-end',
  },
});
export default Header;
