import Constants from "expo-constants";
import provider from "src/Providers";

let selectedGroup = 'Q3EBTruMSFqp';

export function setSelectedGroup(group: string) {
  selectedGroup = group;
}

export const fhirClientProxy = {
  async request(
    requestOptions: any,
    fhirOptions?: any,
  ): Promise<any> {
    let response = await fetch(Constants.manifest.extra.BASE_FHIR_URL + `/${selectedGroup}/${requestOptions.url}`, {
      ...requestOptions,
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
          Accept: 'application/fhir+json',
          'Content-Type': 'application/fhir+json',
          Authorization: `Bearer ${await provider.token()}`,
      },
      // body: JSON.stringify(requestOptions.body) || '',
    });

    switch (response.status) {
          case 401:
              // await refreshTokens();
              // return request(basePath, path, config);
          default:
              break;
      }

    return response.json();
  },
};

export function getFhirClient() {
  return fhirClientProxy;
}
