import React from 'react';
import {Text, StyleSheet} from 'react-native';

interface Props {
  children: React.ReactNode;
}

function Strong({children}: Props) {
  return <Text style={style.strong}>{children}</Text>;
}

const style = StyleSheet.create({
  strong: {
    fontWeight: 'bold',
  },
});

export default Strong;
