import React from 'react';
import {HelperText, TextInput as PaperInput} from 'react-native-paper';
import {StyleSheet, View} from 'react-native';
import {BLUE_RIBBON} from 'src/styles/colors';
import {FastField} from 'formik';

interface Props {
  valueKey: string;
  name: string;
  required?: boolean;
  secureTextEntry?: boolean;
  right?: () => void;
  style?: any;
}

const TextInput = ({
  valueKey,
  name,
  required = false,
  secureTextEntry = false,
  right = () => {},
  style = {},
}: Props) => {
  return (
    <View style={styles.container}>
      <FastField name={valueKey}>
        {({field, meta: {error, touched}}: any) => {
          return (
            <>
              <PaperInput
                onChangeText={field.onChange(field.name)}
                onBlur={field.onBlur(field.name)}
                value={field.value}
                placeholder={name}
                label={name + (required ? '*' : '')}
                error={!!(error && touched)}
                secureTextEntry={secureTextEntry}
                theme={{colors: {primary: BLUE_RIBBON}}}
                right={right}
                mode={'outlined'}
                style={[styles.input, style]}
              />
              <HelperText type="error" visible={true} style={styles.helper}>
                {error && touched && 'ⓧ ' + error}
              </HelperText>
            </>
          );
        }}
      </FastField>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  input: {
    backgroundColor: 'white',
  },
  helper: {
    height: 24,
  },
});

export default TextInput;
