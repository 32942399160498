import Header from '../../common-components/tabs/Header';
import React, {ReactNode} from 'react';
import {memoize} from 'lodash';
import EStyleSheet from 'react-native-extended-stylesheet';
import {useDimensions} from '@react-native-community/hooks';
import {ScrollView, View} from 'react-native';
import {Screen} from 'react-native-screens';

interface Props {
  title: string;
  right?: () => JSX.Element;
  children: ReactNode;
}

const BaseScreen = ({title, right = () => <></>, children}: Props) => {
  const dimensions = useDimensions();

  const styles = getStyles(dimensions.window.width);

  return (
    <Screen style={styles.screen}>
      <ScrollView>
        <Header customTitle={title} style={styles.header} right={right} />
        <View style={styles.container}>{children}</View>
      </ScrollView>
    </Screen>
  );
};

const getStyles = memoize((width: number) =>
  EStyleSheet.create({
    screen: {
      flex: 1,
      width,
    },
    header: {
      marginTop: 20,
      paddingHorizontal: 20,
    },
    container: {
      backgroundColor: 'white',
      paddingVertical: 20,
      borderRadius: 20,
    },
  }),
);

export default BaseScreen;
