import React from 'react';
import {HelperText, RadioButton} from 'react-native-paper';
import {StyleSheet, Text, View} from 'react-native';
import {Colors, Typography} from 'src/styles';
import {FastField} from 'formik';

interface Props {
  valueKey: string;
  name: string;
  required?: boolean;
  buttons: {value: string; title: string}[];
  style?: any;
}

const RadioInput = ({
  valueKey,
  name,
  required = false,
  buttons = [],
  style = {},
}: Props) => {
  return (
    <View style={styles.container}>
      <FastField name={valueKey}>
        {({field, meta: {error, touched}}: any) => {
          return (
            <>
              <Text
                style={[
                  styles.label,
                  {
                    color:
                      error && touched ? Colors.ERROR : Colors.TEXT_PRIMARY,
                  },
                ]}>
                {name + (required ? '*' : '')}
              </Text>
              <RadioButton.Group
                onValueChange={field.onChange(field.name)}
                value={field.value}>
                <View style={styles.radioButtonContainer}>
                  {buttons.map((button: any, index: number) => {
                    return (
                      <View key={index} style={styles.radioButton}>
                        <RadioButton.Android
                          value={button.value}
                          color={
                            error && touched
                              ? Colors.ERROR
                              : Colors.TEXT_PRIMARY
                          }
                          uncheckedColor={
                            error && touched
                              ? Colors.ERROR
                              : Colors.TEXT_PRIMARY
                          }
                        />
                        <Text
                          style={[
                            {
                              ...Typography.INPUT,
                              color:
                                error && touched
                                  ? Colors.ERROR
                                  : Colors.TEXT_PRIMARY,
                            },
                            style,
                          ]}
                          onPress={() => console.log(button.value)}>
                          {button.title}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              </RadioButton.Group>
              <HelperText type="error" visible={true} style={styles.helper}>
                {error && touched && 'ⓧ ' + error}
              </HelperText>
            </>
          );
        }}
      </FastField>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  radioButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  radioButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    ...Typography.LABEL,
    color: Colors.TEXT_SECONDARY,
    paddingBottom: 10,
  },
  helper: {},
});

export default RadioInput;
