import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';
import {ERROR} from 'src/styles/colors';
import {H6, P3} from 'src/styles/typography';
import {Spacing} from 'src/styles';

const Error = ({title, message}) => {
  return (
    <View style={styles.messageContainer}>
      <View style={styles.row}>
        <Icon
          name="times-circle"
          size={18}
          color={ERROR}
          style={styles.headerIcon}
        />
        {title ? (
          <Text style={{...H6, ...styles.title}}>{title}</Text>
        ) : (
          <View />
        )}
      </View>
      {message ? (
        <Text style={{...P3, ...styles.message}}>{message}</Text>
      ) : (
        <View />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  headerIcon: {
    marginLeft: Spacing.SCALE_8,
  },
  messageContainer: {
    backgroundColor: '#f9e7e7',
    height: undefined,
    width: '100%',
    padding: 18,
    borderRadius: 5,
  },
  title: {
    color: ERROR,
    marginLeft: 18,
  },
  message: {
    color: ERROR,
    marginTop: 18,
    marginLeft: 42,
  },
  error: {
    color: ERROR,
  },
});

export default Error;
