import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import {setSelectedPartition} from 'src/services/base/base-client';
import {setSelectedGroup} from 'src/services/base/fhir-client';
import authReducer from './auth';
import userProfileReducer from './user-profile';

const persistConfig = {
  key: 'root',
  storage,
  // whitelist: ['auth', 'userProfile']
};

const combinedReducer = combineReducers({
  auth: persistReducer(persistConfig, authReducer),
  userProfile: persistReducer(persistConfig, userProfileReducer),
});

const fhirClientMiddleware = () => next => action => {
  if (action.type === 'userProfile/fetchUserProfile/fulfilled') {
    setSelectedGroup(action.payload.selectedGroup);
    setSelectedPartition(action.payload.selectedGroup);
  }

  return next(action);
};

const rootReducer = (state: any, action: any) => {
  if (action.type === 'auth/logout') {
    state = undefined;
  }
  return combinedReducer(state, action);
};
export const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware(), fhirClientMiddleware],
});
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
