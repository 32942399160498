import Constants from "expo-constants";
import provider from 'src/Providers';

class OktaUserService {
  getOktaUser = async (id = null) => {
    const uri = `${Constants.manifest.extra.BASE_API_URL}/login${id ? `?id=${id}` : ''}`;
    const config = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await provider.token()}`,
      },
    };
    let jsonResponse = {};
    try {
      let response = await fetch(uri, config);
      jsonResponse = await response.json();
    } catch (e) {
      console.log(e);
    }
    return jsonResponse;
  };
  getCannyToken = async () => {
    const config = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await provider.token()}`,
      },
    };
    let jsonResponse = {};
    try {
      let response = await fetch(`${Constants.manifest.extra.BASE_API_URL}/canny`, config);
      jsonResponse = await response.json();
    } catch (e) {
      console.log(e);
    }
    return jsonResponse;
  };
}
const oktaUserService = new OktaUserService();
export default oktaUserService;
