import React from 'react';
import {Provider as PaperProvider} from 'react-native-paper';
import {Provider as ReduxProvider} from 'react-redux';
import Navigation from 'src/Navigation';
import {Theme} from 'src/common-utils/theme';
import {persistor, store} from 'src/redux/store';
import {useFonts} from "expo-font";
import { AuthProvider } from '@ravenhealth/react-native-auth-provider';
import provider from 'src/Providers';
import { PersistGate } from 'redux-persist/integration/react';
import { IntercomProvider } from "@ravenhealth/react-native-intercom-provider";
import Splash from "src/navigation/components/Splash";
import EStyleSheet from 'react-native-extended-stylesheet';

EStyleSheet.build({});

export default function App() {
  const [loaded] = useFonts({
    'Campton-Black': require('./assets/fonts/Campton/ReneBieder-CamptonBlack.otf'),
    'Campton-BlackItalic': require('./assets/fonts/Campton/ReneBieder-CamptonBlackItalic.otf'),
    'Campton-Bold': require('./assets/fonts/Campton/ReneBieder-CamptonBold.otf'),
    'Campton-BoldItalic': require('./assets/fonts/Campton/ReneBieder-CamptonBoldItalic.otf'),
    'Campton-Book': require('./assets/fonts/Campton/ReneBieder-CamptonBook.otf'),
    'Campton-BookItalic': require('./assets/fonts/Campton/ReneBieder-CamptonBookItalic.otf'),
    'Campton-ExtraBold': require('./assets/fonts/Campton/ReneBieder-CamptonExtraBold.otf'),
    'Campton-ExtraBoldItalic': require('./assets/fonts/Campton/ReneBieder-CamptonExtraBoldItalic.otf'),
    'Campton-ExtraLight': require('./assets/fonts/Campton/ReneBieder-CamptonExtraLight.otf'),
    'Campton-ExtraLightItalic': require('./assets/fonts/Campton/ReneBieder-CamptonExtraLightItalic.otf'),
    'Campton-Light': require('./assets/fonts/Campton/ReneBieder-CamptonLight.otf'),
    'Campton-LightItalic': require('./assets/fonts/Campton/ReneBieder-CamptonLightItalic.otf'),
    'Campton-Medium': require('./assets/fonts/Campton/ReneBieder-CamptonMedium.otf'),
    'Campton-MediumItalic': require('./assets/fonts/Campton/ReneBieder-CamptonMediumItalic.otf'),
    'Campton-SemiBold': require('./assets/fonts/Campton/ReneBieder-CamptonSemiBold.otf'),
    'Campton-SemiBoldItalic': require('./assets/fonts/Campton/ReneBieder-CamptonSemiBoldItalic.otf'),
    'Campton-Thin': require('./assets/fonts/Campton/ReneBieder-CamptonThin.otf'),
    'Campton-ThinItalic': require('./assets/fonts/Campton/ReneBieder-CamptonThinItalic.otf'),
  });

  if (!loaded) {
    return <Splash />;
  }

  return (
    <IntercomProvider>
      <AuthProvider provider={provider}>
        <ReduxProvider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <PaperProvider theme={Theme}>
              <Navigation />
            </PaperProvider>
          </PersistGate>
        </ReduxProvider>
      </AuthProvider>
    </IntercomProvider>
  );
}
