import React from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {Portal, Modal} from 'react-native-paper';
import * as colors from 'src/styles/colors';
import {MaterialCommunityIcons} from "@expo/vector-icons";
import {Typography} from "src/styles";

interface props {
  hideModal: () => void;
  visible: boolean;
  children?: JSX.Element[] | JSX.Element;
  footer?: JSX.Element[] | JSX.Element;
  style?: Object;
  size: Size;
  title: string | undefined;
}

export enum Size {
  SMALL,
  MEDIUM,
  LARGE,
}

const RHModal = ({hideModal, visible, children = <></>, footer = <></>, style = {}, size = Size.LARGE, title}: props) => {
  switch (size) {
    case Size.SMALL:
    case Size.MEDIUM:
      style = {
        ...style,
        width: 696
      };
      break;
    case Size.LARGE:
      style = {
        ...style,
        width: 732
      };
      break;
  }

  return (
    <>
      <Portal>
        <Modal
          visible={visible}
          onDismiss={hideModal}
          contentContainerStyle={[styles.modalContainer, style]}>
          <View style={styles.headerContainer}>
            {title ? <Text style={Typography.H4}>{title}</Text>: <></>}
            <TouchableOpacity onPress={hideModal} style={styles.closeIcon}>
              <MaterialCommunityIcons name="close" size={24} />
            </TouchableOpacity>
          </View>
          {children}
          <View style={styles.footerContainer}>
            {footer}
          </View>
        </Modal>
      </Portal>
    </>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    maxHeight: '80%',
    maxWidth: 732,
    backgroundColor: colors.RAVEN_WHITE,
    padding: 40,
    borderRadius: 20,
    alignSelf: 'center',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 20,
    width: '100%',
  },
  closeIcon: {
    marginLeft: 'auto',
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  }
});

export default RHModal;
