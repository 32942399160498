export const BACKGROUND = require('../../assets/images/RHLoginBG.png');
export const LOGO = require('../../assets/images/loginLogo.png');
export const SPLASH = require('../../assets/images/RHSplash.png');
export const PlusIcon = require('../../assets/images/plus-icon.png');
export const CheckIcon = require('../../assets/images/check-icon.png');
export const StaffIcon = require('../../assets/images/staffIcon.png');
export const PatientIcon = require('../../assets/images/patientIcon.png');
export const CloseIcon = require('../../assets/images/closeIcon.png');
export const CALENDARICON = require('../../assets/icons/calendarIcon.png');
export const CONTACTICON = require('../../assets/icons/contactIcon.png');
export const PROFILEICON = require('../../assets/icons/profileIcon.png');
export const SEARCHICON = require('../../assets/icons/searchIcon.png');
export const CAUTIONICON = require('../../assets/icons/cautionIcon.png');
export const OPTIONSICON = require('../../assets/images/options.png');
