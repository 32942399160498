import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import oktaUserService from 'src/services/okta-user-service';
import practitionerService from 'src/services/practitioner-service';
import practitionerRoleService from 'src/services/practitioner-role-service';
import {useIntercom} from "@ravenhealth/react-native-intercom-provider";

export const fetchUserProfile = createAsyncThunk(
  'userProfile/fetchUserProfile',
  async () => {
    let oktaProfile = await oktaUserService.getOktaUser();

    let groups = oktaProfile?.groups.currentPage.items.filter(
      item => item.type !== 'BUILT_IN',
    );

    return {
      oktaProfile,
      groups,
      selectedGroup: groups[0].profile.name,
    };
  },
);

export const fetchPractitionerProfile = createAsyncThunk(
  'userProfile/fetchPractitionerProfile',
  async (arg, {getState}) => {
    const state = getState();
    const [practitionerResponse, practitionerRoleResponse] = await Promise.all([
      practitionerService.getPractitioners(
        `identifier=${state?.userProfile?.oktaProfile?.id}`,
      ),
      practitionerRoleService.getPractitionerRoles(
        `practitioner.identifier=${state?.userProfile?.oktaProfile?.id}`,
      ),
    ]);

    return {
      practitioner: practitionerResponse.entry[0].resource,
      practitionerRole: practitionerRoleResponse.entry[0].resource,
    };
  },
);

export const fetchUpdatePractitioner = createAsyncThunk(
  'userProfile/updatePractitioner',
  async ({
    practitioner,
    practitionerRole,
  }: {
    practitioner: any;
    practitionerRole: any;
  }) => {
    await Promise.all([
      practitionerService.updatePractitioner(practitioner),
      practitionerRoleService.updatePractitionerRole(practitionerRole),
    ]);

    return {
      practitioner,
      practitionerRole,
    };
  },
);

export const fetchCreatePractitioner = createAsyncThunk(
  'userProfile/fetchCreatePractitioner',
  async (payload: any) => {
    return await practitionerService.updatePractitioner(payload);
  },
);

const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState: {
    loading: false,
    oktaProfile: {},
    practitioner: {
      id: '',
      resourceType: 'Practitioner',
      identifier: [],
      active: true,
      name: [],
      telecom: [],
      address: [],
      gender: '',
      birthDate: '',
    },
    groups: [],
    selectedGroup: '',
    practitionerRole: {},
    error: true,
  },
  reducers: {
    updatePractitioner(state, action) {
      state.practitioner = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchUserProfile.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
      state.oktaProfile = action.payload.oktaProfile;
      state.groups = action.payload.groups;
      state.selectedGroup = action.payload.selectedGroup;
      state.loading = false;
      state.error = false;
    });
    builder.addCase(fetchUserProfile.rejected, state => {
      state.error = true;
      state.loading = false;
    });
    builder.addCase(fetchPractitionerProfile.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchPractitionerProfile.fulfilled, (state, action) => {
      state.practitioner = {
        ...state.practitioner,
        ...action.payload.practitioner,
      };
      state.practitionerRole = action.payload.practitionerRole;
      state.loading = false;
    });
    builder.addCase(fetchPractitionerProfile.rejected, state => {
      state.loading = false;
    });
    builder.addCase(fetchCreatePractitioner.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchCreatePractitioner.fulfilled, (state, action) => {
      state.practitioner = {
        ...state.practitioner,
        ...action.payload,
      };
      state.loading = false;
    });
    builder.addCase(fetchCreatePractitioner.rejected, state => {
      state.loading = false;
    });

    // fetchUpdatePractitioner
    builder.addCase(fetchUpdatePractitioner.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchUpdatePractitioner.fulfilled, (state, action) => {
      state.practitioner = {
        ...state.practitioner,
        ...action.payload.practitioner,
      };
      state.practitionerRole = action.payload.practitionerRole;
      state.loading = false;
    });
    builder.addCase(fetchUpdatePractitioner.rejected, state => {
      state.loading = false;
    });
  },
});

export const {updatePractitioner} = userProfileSlice.actions;

export default userProfileSlice.reducer;
