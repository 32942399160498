import React from 'react';
import {StyleSheet, Text} from 'react-native';

const InputError = ({errorMessage}) => {
  return <Text style={styles.lineError}>ⓧ {errorMessage}</Text>;
};

const styles = StyleSheet.create({
  lineError: {
    fontSize: 12,
    paddingLeft: 10,
    paddingVertical: 10,
    width: '100%',
    color: '#C50F0F',
  },
});

export default InputError;
