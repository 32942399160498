import React, {useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {StyleSheet, TouchableOpacity, Text, View} from 'react-native';
import {FormInput} from 'src/design-system';
import {TextInput} from 'react-native-paper';
import {RHButton} from 'src/common-components/custom-ui-helpers';

interface Props {
  handleSubmit: (payload: any, formik: any) => void;
}

const LoginForm = ({handleSubmit}: Props) => {
  const [hidePassword, setHidePassword] = useState(true);

  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
      }}
      validationSchema={Yup.object({
        username: Yup.string()
          .email('Invalid email')
          .required('Please enter a valid email address.'),
        password: Yup.string().required('Please enter a valid password.'),
      })}
      onSubmit={handleSubmit}>
      {({
        errors,
        // setErrors,
        values,
        setValues,
        touched,
        setTouched,
        // resetForm,
        submitForm,
      }) => (
        <>
          <FormInput
            valueKey={'username'}
            text={'Email'}
            required={true}
            errors={errors}
            values={values}
            setValues={setValues}
            touched={touched}
            setTouched={setTouched}
          />
          <FormInput
            secureTextEntry={hidePassword}
            valueKey={'password'}
            text={'Password'}
            required={true}
            errors={errors}
            values={values}
            setValues={setValues}
            touched={touched}
            setTouched={setTouched}
            right={
              <TextInput.Icon
                name={hidePassword ? 'eye-off' : 'eye'}
                onPress={() => setHidePassword(!hidePassword)}
                touchSoundDisabled={true}
              />
            }
          />
          <View style={styles.actions}>
            <RHButton onPress={submitForm} mode={'contained'} secondary>
              <Text>LOGIN</Text>
            </RHButton>
          </View>
        </>
      )}
    </Formik>
  );
};

const styles = StyleSheet.create({
  input: {},
  actions: {
    paddingVertical: 10,
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 24,
  },
  actionButtonText: {
    color: 'grey',
    textAlign: 'right',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export default LoginForm;
