import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import okta from "src/Providers";

export const fetchAuthStatus = createAsyncThunk(
  'users/fetchAuthStatus',
  async () => {
    return !!await okta.token();
  },
);

export const fetchSignOut = createAsyncThunk('users/fetchSignOut', async () => {
  try {
    // TODO: Finish Sign Out in Auth Provider
    okta.authClient?.closeSession();
  } catch (error) {
    console.log(error);
  }
  return '';
});

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    fetched: false,
    isAuthenticated: false,
    loading: false,
  },
  reducers: {},
  extraReducers: builder => {
    // args -> action type, reducer funtion which passes state to the function
    // body and updates state.loading to true
    builder.addCase(fetchAuthStatus.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchAuthStatus.fulfilled, (state, action) => {
      state.isAuthenticated = action.payload;
      state.loading = false;
      state.fetched = true;
    });
    builder.addCase(fetchAuthStatus.rejected, state => {
      state.loading = false;
    });

    //  signOut
    builder.addCase(fetchSignOut.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchSignOut.fulfilled, state => {
      state.isAuthenticated = false;
      state.loading = false;
    });
    builder.addCase(fetchSignOut.rejected, state => {
      state.loading = false;
    });
  },
});

export default authSlice.reducer;
