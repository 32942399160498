import {Typography, Colors} from 'src/styles';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {Chip} from 'react-native-paper';

const RHChip = (props: any) => {
  const obj = {
    success: Colors.SUCCESS,
    info: Colors.INFO,
    error: Colors.ERROR,
    inactive: Colors.GRAY_500,
  };

  // here we are removing the style props via destructuring as to
  // not create any unintended bugs with destructured style props
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {style, ...defaultProps} = props;

  const textStyle = props.secondary
    ? Typography.P3_MEDIUM
    : Typography.PRIMARY_BUTTON;

  return (
    <View style={styles.bg}>
      <Chip
        textStyle={{color: obj[props.type]}}
        style={{...styles[props.type], ...props.style}}
        theme={{
          fonts: {regular: textStyle},
        }}
        {...defaultProps}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  success: {
    backgroundColor: Colors.SUCCESS_LIGHT,
  },
  info: {
    backgroundColor: Colors.INFO_LIGHT,
  },
  error: {
    backgroundColor: Colors.ERROR_LIGHT,
  },
  inactive: {
    backgroundColor: Colors.OPACITY_6,
  },
  //This will allow us to set a soft background color using RGBA colors
  bg: {
    backgroundColor: 'white',
    borderRadius: 100,
  },
});

export default RHChip;
