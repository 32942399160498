import React, {ReactNode, useState} from 'react';
import {HelperText, TextInput} from 'react-native-paper';
import {StyleSheet, View} from 'react-native';
import {Colors, Typography} from 'src/styles';
import {Field} from 'formik';
import DropDown from 'react-native-paper-dropdown';
import {dropdownTheme} from 'src/common-utils/theme';

interface Props {
  valueKey: string;
  name: string;
  required?: boolean;
  items: {
    label: string;
    value: string | number;
    custom?: ReactNode;
  }[];
  style?: any;
}

const SelectInput = ({
  valueKey,
  name,
  required = false,
  items = [],
  style = {},
}: Props) => {
  const [openStateDropDown, setOpenStateDropDown] = useState(false);

  return (
    <View style={styles.container}>
      <Field name={valueKey}>
        {({field, meta: {error, touched}}: any) => {
          return (
            <>
              <DropDown
                label={name + (required ? '*' : '')}
                value={field.value}
                placeholder={name + (required ? '*' : '')}
                setValue={field.onChange(field.name)}
                list={items}
                visible={openStateDropDown}
                showDropDown={() => {
                  setOpenStateDropDown(true);
                }}
                onDismiss={() => setOpenStateDropDown(false)}
                theme={dropdownTheme}
                mode={'outlined'}
                inputProps={{
                  style: {...style, width: '100%'},
                  error: !!(error && touched),
                  right: <TextInput.Icon name={'menu-down'} />,
                }}
              />
              <HelperText type="error" visible={true} style={styles.helper}>
                {error && touched && 'ⓧ ' + error}
              </HelperText>
            </>
          );
        }}
      </Field>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  label: {
    ...Typography.LABEL,
    color: Colors.TEXT_SECONDARY,
  },
  helper: {},
});

export default SelectInput;
