import React from 'react';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {Formik} from 'formik';
import {Colors} from 'src/styles';
import {View} from 'react-native';
import {Separator} from 'src/common-components/atoms';
import {memoize} from 'lodash';
import EStyleSheet from 'react-native-extended-stylesheet';

interface Props {
  handleSubmit?: (payload: any, formik: any) => void;
  cancelled?: (formik: any) => void;
  initialValues: any;
  validationSchema: any;
  children: JSX.Element;
}

const Form = ({
  handleSubmit = async (values, formik) => console.log(values, formik),
  cancelled = () => {},
  initialValues,
  validationSchema,
  children,
}: Props) => {
  const styles = getStyles();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {formik => {
        return (
          <View style={[styles.flex, styles.screenPadding]}>
            {children}
            <View style={styles.actions}>
              <RHButton
                onPress={() => cancelled(formik)}
                textColor={Colors.RAVEN_BLACK}
                color="black"
                mode="outlined">
                Cancel
              </RHButton>
              <Separator width={16} />
              <RHButton mode="contained" onPress={formik.submitForm}>
                Save
              </RHButton>
            </View>
          </View>
        );
      }}
    </Formik>
  );
};

const getStyles = memoize(() =>
  EStyleSheet.create({
    flex: {
      flex: 1,
    },
    screenPadding: {
      paddingHorizontal: 20,
    },
    actions: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
    btn: {},
  }),
);

export default Form;
