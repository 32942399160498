import {Colors} from '../styles';
import {DefaultTheme as DefaultPaperTheme} from 'react-native-paper';
import {DefaultTheme as DefaultNavTheme} from '@react-navigation/native';
export const Theme = {
  ...DefaultPaperTheme,
  ...DefaultNavTheme,
  colors: {
    ...DefaultPaperTheme.colors,
    ...DefaultNavTheme.colors,
    primary: Colors.BLUE_RIBBON,
    background: '#F7F7FA',
  },
};

export const dropdownTheme = {
  colors: {
    background: 'white',
    primary: Colors.BLUE_RIBBON,
    text: 'black',
  },
};
