import {getFhirClient} from './base/fhir-client';

const client = getFhirClient();

class PractitionerRoleService {
  getPractitionerRoles = async query => {
    return await client.request({
      url: `PractitionerRole?${query}`,
    });
  };
  getPractitionerRole = async id => {
    return await client.request({
      url: `PractitionerRole/${id}`,
    });
  };

  updatePractitionerRole = async practitionerRole =>
    await client.request({
      url: `PractitionerRole/${practitionerRole.id}`,
      method: 'PUT',
      body: practitionerRole,
    });
}

const practitionerRoleService = new PractitionerRoleService();

export default practitionerRoleService;
