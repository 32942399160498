import React from 'react';
import _ from 'lodash';
import Svg from 'react-native-svg';

const SVG = ({ name, fill, width, height, ...otherProps }) => {
    const graphics = {
        logo: {
            width: 380,
            height: 105,
            content: <svg width="380" height="105" fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 379.59 104.16">
                <path d="M165.68,66.69a20.42,20.42,0,0,1,15.2-6.1v9.63c-6.63.27-11.58,2.57-15.2,7v26H155.42V61.38h10.26Z"/>
                <path d="M264.94,103.19h-9L236.66,61.38h10.87L260.44,90.2l13-28.82h10.78Z"/>
                <path d="M328.76,86.31H296.68c1.23,5.21,5.12,9.19,12.19,9.19,4.07,0,7-1.32,8.49-3.89h10.87c-2.38,7.69-10.07,12.55-19.62,12.55-13.35,0-22.19-9.45-22.19-21.83s8.84-21.92,22.19-21.92c11.58,0,20.33,8.66,20.42,21.21A27.81,27.81,0,0,1,328.76,86.31Zm-32.17-7.6h22.1c-.8-5.92-4.51-9.64-10.61-9.64C301.54,69.07,297.74,73.32,296.59,78.71Z"/>
                <path d="M379.59,80.12v23.07H369.42V80.74c0-7.34-3.89-11.4-10.25-11.4-3.62,0-7.6,2.12-10.78,6v27.84H338.13V61.38h10.26v3.45a19.63,19.63,0,0,1,12.9-4.77C372.25,60.06,379.59,68,379.59,80.12Z"/>
                <path d="M219.6,61.38V64.3a21.6,21.6,0,0,0-12.82-3.89c-11.67,0-21,9.72-21,21.92s9.37,21.83,21,21.83a21.66,21.66,0,0,0,12.82-3.88v2.91h10.16V61.38Zm-11,34.16a13.29,13.29,0,1,1,13.28-13.28A13.29,13.29,0,0,1,208.64,95.54Z"/>
                <path d="M128.29,6.94C128.21,6.83,123.61,0,111.35,0S93.83,8.14,93.83,8.14c-12.52,2.44-16.76,9.53-16.76,9.53l23.38.31c2.35.84,4.66,2.87,6.81,5.44L29.94,76.62H43.67L0,102.2l82.07-9.47,22.8,11.43S129,64.69,131.81,49.79C133.83,39.23,128.29,6.94,128.29,6.94Z"/>
            </svg>
        }
    }
    const viewBoxWidth = graphics[name].width
    const viewBoxHeight = graphics[name].height
    const viewBoxRatio = viewBoxWidth / viewBoxHeight

    return (
        <Svg
            width={width || height && _.parseInt(height * viewBoxRatio) || 100}
            height={height || width && _.parseInt(width / viewBoxRatio) || 100}
            viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
            {...otherProps}
        >
            {graphics[name].content}
        </Svg>
    )

}

export default SVG;
