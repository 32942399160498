import Constants from "expo-constants";
import { Okta } from '@ravenhealth/react-native-auth-provider';

const okta = new Okta(
    Constants.manifest.extra.AUTH_CLIENT_ID,
    Constants.manifest.extra.AUTH_REDIRECT_URI,
    Constants.manifest.extra.AUTH_END_SESSION_REDIRECT_URI,
    Constants.manifest.extra.AUTH_DISCOVERY_URI
);

export default okta;

