import React, {useEffect, useRef} from 'react'
import {Platform} from 'react-native'
import * as Linking from 'expo-linking';
import {useSelector} from 'react-redux';
import {createDrawerNavigator} from '@react-navigation/drawer';
import {
    createNavigationContainerRef,
    NavigationContainer,
} from "@react-navigation/native";
import {LoginScreen} from "../modules";
import {useAuth} from "@ravenhealth/react-native-auth-provider";
import {useDispatch} from "react-redux";
import {fetchAuthStatus} from "src/redux/auth";
import {fetchPractitionerProfile, fetchUserProfile} from "src/redux/user-profile";
import Splash from "src/navigation/components/Splash";
import OktaUserService from "src/services/okta-user-service";

function Navigation() {
    const auth = useAuth();
    const dispatch = useDispatch();
    const routeNameRef = useRef<string>();
    const RootStack = createDrawerNavigator();
    const navigationRef = createNavigationContainerRef()

    const Theme = {
        dark: false,
        colors: {
            primary: '#ffffff',
            background: 'rgb(242, 242, 242)',
            card: 'rgb(255, 255, 255)',
            text: '#000000',
            border: 'rgb(216, 216, 216)',
            notification: 'rgb(255, 59, 48)',
        },
    };

    const linking = {
        prefixes: [
            'localhost',
            'https://ravenhealth.com',
            'https://www.ravenhealth.com',
            'ravenhealth://',
        ],
        config: {
            screens: {
                Login: 'login',
            },
        },
    };

    const CallbackScreen = ({navigation}: any) => {
        const auth = useAuth();
        const {loading, selectedGroup} = useSelector(state => state.userProfile);

        const parseAndLogToken = async () => {
            auth.provider.parseToken().then(() => {
                auth.provider.token().then(async (token: string) => {
                    await dispatch(fetchUserProfile());
                    await dispatch(fetchPractitionerProfile());
                    await dispatch(fetchAuthStatus());
                })
            });
        };

        const cannyLink = async () => {
            const cannyParams = JSON.parse(localStorage.getItem('params'));
            Linking.openURL('https://canny.io/api/redirects/sso?companyID=' + cannyParams.companyID + '&ssoToken=' + (await OktaUserService.getCannyToken()).token + '&redirect=' + cannyParams.redirect);
        }

        useEffect(() => {
            if (selectedGroup !== '' && !loading) {
                cannyLink();
            }
        }, [selectedGroup, loading]);

        useEffect(() => {
            if (Platform.OS === 'web' && auth.provider.isLoginRedirect()) {
                parseAndLogToken();
            }
        }, []);
      return <Splash />
    };

    const SplashScreen = ({navigation}: any) => {
        navigation.navigate('login');

        return <Splash />
    };

    return (
        <NavigationContainer
            ref={navigationRef}
            linking={linking}
            theme={Theme}
            onReady={() => {
                routeNameRef.current = navigationRef.getCurrentRoute()?.name;
                console.log(routeNameRef.current);
            }}
            onStateChange={async () => {
                const previousRouteName = routeNameRef.current;
                const currentRouteName = navigationRef.getCurrentRoute()?.name;

                if (previousRouteName !== currentRouteName) {
                    // TODO: Implement Mixpanel Navigation Callback
                    console.log('value', currentRouteName);
                }

                routeNameRef.current = currentRouteName;
            }}
        >
            <RootStack.Navigator
                initialRouteName={
                    Platform.OS === 'web' && auth.provider.isLoginRedirect() ?
                        'callback' :
                        'login'
                }
                screenOptions={{
                    drawerLabelStyle: {
                        color: '#ffffff',
                    },
                    drawerActiveTintColor: '#000000',
                    drawerActiveBackgroundColor: '#ffffff',
                    drawerStyle: {
                        backgroundColor: '#000000',
                        width: 304,
                    },
                }}>
                {/*<RootStack.Screen*/}
                {/*    name="splash"*/}
                {/*    component={SplashScreen}*/}
                {/*    options={{*/}
                {/*        headerShown: false*/}
                {/*    }}*/}
                {/*/>*/}
                <RootStack.Screen
                    name="login"
                    component={LoginScreen}
                    options={{
                        headerShown: false
                    }}
                />
                <RootStack.Screen
                    name="callback"
                    component={CallbackScreen}
                    options={{
                        headerShown: false
                    }}
                />
            </RootStack.Navigator>
        </NavigationContainer>
    );
}

export default Navigation
