import {signIn} from '@okta/okta-react-native';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {
  fetchPractitionerProfile,
  fetchUserProfile,
} from 'src/redux/user-profile';
import {BACKGROUND} from 'src/styles/images';
import SVG from "src/styles/svg";
import BaseAuthScreen from 'src/modules/registration/screens/base-auth-screen';
import {LoginForm} from 'src/modules/registration/components';
import _ from 'lodash';
import {useAuth} from "@ravenhealth/react-native-auth-provider";
import * as Linking from "expo-linking";

const LoginScreen = ({navigation}: any) => {
  const auth = useAuth();
  const dispatch = useDispatch();

  const [progress, setProgress] = useState(false);
  const [toast, setToast] = useState<null | any>(null);

  Linking.parseInitialURLAsync().then((url) => {
    if (!_.isEmpty(url.queryParams)) {
      localStorage.setItem('params', JSON.stringify(url.queryParams));
    }
  });

  const handleSubmit = async (payload: any, formik: any) => {
    setProgress(true);
    try {
      const response = await auth.provider.signIn(payload);
      console.log(response);
      if (!response.failed) {
        await dispatch(fetchUserProfile());
        await dispatch(fetchPractitionerProfile());
        formik.resetForm();
        setToast(null);
        navigation.navigate('Home', {screen: 'Home'});
      } else {
        setToast({
          success: false,
          title: 'Invalid Username or Password.',
          message: '',
        });
      }
    } catch (err) {
      setToast({
        success: false,
        title: 'Invalid Username or Password.',
        message: '',
      });
    } finally {
      setProgress(false);
    }
  };

  return (
    <BaseAuthScreen
      loading={progress}
      background={BACKGROUND}
      logo={<SVG height={45} name={'logo'} />}
      toast={toast}>
      <LoginForm
        handleSubmit={handleSubmit}
      />
    </BaseAuthScreen>
  );
};

export default LoginScreen;
