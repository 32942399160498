import provider from "src/Providers";

export enum HTTP_METHOD {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export type Config = {
  method: HTTP_METHOD;
  body: string;
};

let selectedPartition = 'Q3EBTruMSFqp';

export function setSelectedPartition(partition: string) {
  selectedPartition = partition;
}

export const request = async (
  basePath: string,
  path: string,
  config: Config,
): Promise<any> => {
  let response = await fetch(`${basePath}/${selectedPartition}/${path}`, {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await provider.token()}`,
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    ...config,
  });

  switch (response.status) {
    case 401:
      // await refreshTokens();
      return request(basePath, path, config);
    default:
      break;
  }

  return response.json();
};
