import React from 'react';
import {Text, StyleSheet} from 'react-native';

interface Props {
  children: React.ReactNode;
}

function Em({children}: Props) {
  return <Text style={style.em}>{children}</Text>;
}

const style = StyleSheet.create({
  em: {
    fontStyle: 'italic',
  },
});

export default Em;
