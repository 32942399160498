import React from 'react';
import {StyleSheet, KeyboardAvoidingView, Platform, View} from 'react-native';
const isIOS = Platform.OS === 'ios';

interface props {
  children: any[] | any;
  style?: object;
  behavior?: 'padding' | 'height' | 'position' | undefined;
}

const RHKeyboardAvoidingView = ({
  children,
  behavior = 'padding',
  style = {},
}: props) => {
  return isIOS ? (
    <KeyboardAvoidingView
      behavior={behavior}
      style={{...styles.default, ...style}}>
      {children}
    </KeyboardAvoidingView>
  ) : (
    <View style={{...styles.default, ...style}}>{children}</View>
  );
};

const styles = StyleSheet.create({
  default: {},
});

export default RHKeyboardAvoidingView;
