import React, {ReactNode} from 'react';
import {
  ImageBackground,
  ImageSourcePropType,
  ScrollView,
  View,
} from 'react-native';
import {Screen} from 'react-native-screens';
import {memoize} from 'lodash';
import EStyleSheet from 'react-native-extended-stylesheet';
import {useDimensions} from '@react-native-community/hooks';
import Spinner from 'react-native-loading-spinner-overlay';
import {Error, Separator, Success} from 'src/common-components/atoms';

interface BaseAuthScreenProps {
  background: ImageSourcePropType;
  children?: any;
  loading?: boolean;
  logo: ReactNode;
  toast?: any;
}

const BaseAuthScreen = ({
  background,
  children,
  loading = false,
  logo,
  toast = null,
}: BaseAuthScreenProps) => {
  const dimensions = useDimensions();

  const styles = getStyles(dimensions.window.width, dimensions.window.height);

  return (
    <Screen style={styles.screen}>
      <Spinner visible={loading} />
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={styles.scroll}
        bounces={false}>
        <ImageBackground source={background} style={styles.screenContainer}>
          <View style={styles.formContainer}>
            <View style={styles.logo}>{logo}</View>
            {toast && toast.success ? (
              <Success title={toast.title} message={toast.message} />
            ) : toast && !toast.success ? (
              <Error title={toast.title} message={toast.message} />
            ) : (
              <></>
            )}
            {toast ? <Separator height={20} /> : <></>}
            {children}
          </View>
        </ImageBackground>
      </ScrollView>
    </Screen>
  );
};

const getStyles = memoize((width: number, height: number) =>
  EStyleSheet.create({
    screen: {
      flex: 1,
      height: height,
      width: width,
    },
    scroll: {
      // height: height,
    },
    screenContainer: {
      minHeight: height,
      width: width,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingHorizontal: 24,
      paddingVertical: 40,
    },
    formContainer: {
      backgroundColor: 'white',
      borderRadius: 20,
      flexDirection: 'column',
      paddingHorizontal: 20,
      paddingVertical: 26,
      width: '100%',
      maxWidth: 500,
    },
    logo: {
      width: '100%',
      alignSelf: 'center',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginBottom: 20,
    },
    errors: {
      marginVertical: 10,
    },
  }),
);

export default BaseAuthScreen;
