import * as React from 'react';
import {Dialog} from 'react-native-paper';
import {Keyboard, Platform} from 'react-native';

export default class RHKeyboardAwareDialogWrapper extends React.Component {
  constructor(props: any) {
    super(props);
    this.subscriptions = [];
    this.state = {
      bottom: 0,
      height: undefined,
    };
  }

  componentDidMount() {
    if (Platform.OS === 'ios') {
      this.subscriptions = [
        Keyboard.addListener(
          'keyboardWillChangeFrame',
          this.onKeyboardChange.bind(this),
        ),
      ];
    } else {
      this.subscriptions = [
        Keyboard.addListener(
          'keyboardDidHide',
          this.onKeyboardChange.bind(this),
        ),
        Keyboard.addListener(
          'keyboardDidShow',
          this.onKeyboardChange.bind(this),
        ),
      ];
    }
  }

  componentWillUnmount() {
    this.subscriptions.forEach(subscription => {
      subscription.remove();
    });
  }

  onKeyboardChange(e: any) {
    if (Platform.OS === 'android') {
      return;
    }
    if (e.endCoordinates.screenY <= e.startCoordinates.screenY) {
      this.setState({
        bottom: e.endCoordinates.height / 2,
        height: e.endCoordinates.screenY,
      });
    } else {
      this.setState({bottom: 0, height: undefined});
    }
  }

  render() {
    return (
      <Dialog
        style={{
          bottom: this.state.bottom,
          ...this.props.style,
          height: this.state.height,
        }}
        visible={this.props.visible}
        onDismiss={this.props.onDismiss}>
        {this.props.children}
      </Dialog>
    );
  }
}
